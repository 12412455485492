import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionSubstance, { SectionSubstanceBody } from 'components/SectionSubstance'
import PartnerCard from 'components/PartnerCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import Panorama from 'components/Panorama'
import WebhotSwiper from 'components/WebhotSwiper'
import { WebhotState } from '../tool/index.zh'

import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import PageBottomCard from 'components/PageBottomCard'
import { Languages } from 'i18n/config'

import * as styles from './index.module.less'

const WebhotCar: React.FC = (props) => {
  const demoUrl = ''
  const paradeUrl = ''
  const { webhotCarYaml } = useStaticQuery(graphql`
    query {
      webhotCarYaml {
        title
        description
        keywords
        software_title
        software_titleMb
        saTitle
        software_sectionCardList {
          desc
          title
          cover
        }
        software_buttonText
        software_qrcode
        panorama_title
        panorama_titleMb
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_dataSource {
          title
          panorama_imgUrl
        }
        partner_desc
        partner_title
        partner_titleMb
        partner_imgBottomText
        partner_banner
        partner_bannerMb
        partner_dataList {
          imgUrl
        }
        partner_buttonText
        partner_qrcode
        cargo_title
        cargo_titleMb
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        banner_title
        banner_Desc
        banner_buttonHref
        banner_buttonText
        banner_qrcodeButtonText
        banner_sectionTwoDesc
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_icon
        banner_icon_mb
        banner_dataSource {
          buttonHref
          buttonText
          dataTitle
          descList
          qrcodeUrl
        }
        banner_list {
          isActive
          name
          url
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <WebhotState
        demoUrl={demoUrl}
        paradeUrl={paradeUrl}
        webhotYaml={webhotCarYaml}
        slotPanoramaSection={
          <SectionSubstance
            titleTextAlignMb="left"
            className={classnames('bg-[#fff]', styles.webhotCarPanorama)}
            titleMb={webhotCarYaml?.panorama_titleMb}
            title={webhotCarYaml?.panorama_title}
            descText={webhotCarYaml?.panorama_desc}
          >
            <SectionSubstanceBody>
              <WebhotSwiper dataSource={webhotCarYaml?.panorama_dataSource} />
              <div className="text-center mt-[40px]">
                <ScanCodeConsultButton
                  buttonText={webhotCarYaml?.panorama_btnText}
                  shape="circle"
                  qrcode={webhotCarYaml?.panorama_btnCode}
                />
              </div>
            </SectionSubstanceBody>
          </SectionSubstance>
        }
        slotPartnerSection={
          <SectionSubstance
            className={classnames(styles.webhotCarPartner)}
            titleTextAlignMb="left"
            title={webhotCarYaml?.partner_title}
            titleMb={webhotCarYaml?.partner_titleMb}
            descText={webhotCarYaml?.partner_desc}
          >
            <PartnerCard className="lg:mt-[40px] mb-[20px]" dataSource={webhotCarYaml?.partner_dataList} />
            <div className="text-center">
              <ScanCodeConsultButton
                shape="circle"
                imgBottomText={webhotCarYaml?.partner_imgBottomText}
                buttonText={webhotCarYaml?.partner_buttonText}
                qrcode={webhotCarYaml?.partner_qrcode}
              />
            </div>
          </SectionSubstance>
        }
      />
    </Layout>
  )
}

export default WebhotCar
